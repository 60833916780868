// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD';

//List Detail
export const LIST_DETAIL_BY_KEY = 'LIST_DETAIL_BY_KEY';
export const SHOW_LIST_DETAIL = 'SHOW_LIST_DETAIL';
export const LIST_DETAIL_LOADING = 'LIST_DETAIL_LOADING';
export const LIST_DETAIL_VISIBLE = 'LIST_DETAIL_VISIBLE';
export const LIST_DETAIL_STATUS_CREATE = 'LIST_DETAIL_STATUS_CREATE';
export const LIST_DETAIL_STATUS_ACTIONS = 'LIST_DETAIL_STATUS_ACTIONS';
export const ADD_LIST_DETAIL = 'ADD_LIST_DETAIL';
export const DELETE_LIST_DETAIL = 'DELETE_LIST_DETAIL';
export const GET_LIST_DETAIL = 'GET_LIST_DETAIL';
export const UPDATE_LIST_DETAIL = 'UPDATE_LIST_DETAIL';
export const LIST_DETAIL_TYPE_OPERATION = 'LIST_DETAIL_TYPE_OPERATION';

//Cash
export const SHOW_CASHES = 'SHOW_CASHES';
export const CASH_LOADING = 'CASH_LOADING';
export const CASH_VISIBLE = 'CASH_VISIBLE';
export const CASH_STATUS_CREATE = 'CASH_STATUS_CREATE';
export const CASH_STATUS_ACTIONS = 'CASH_STATUS_ACTIONS';
export const ADD_CASH = 'ADD_CASH';
export const LIST_CASHES = 'LIST_CASHES';
export const LIST_OPENING = 'LIST_OPENING';
export const CASH_GETINFO = 'CASH_GETINFO';
export const ACTIVE_CASH = 'ACTIVE_CASH';
export const DELETE_CASH = 'DELETE_CASH';
export const GET_CASH = 'GET_CASH';
export const UPDATE_CASH = 'UPDATE_CASH';
export const OPEN_CASH = 'OPEN_CASH';
export const CLOSE_CASH = 'CLOSE_CASH';

//Cash Movement

export const SHOW_CASH_MOVEMENT = 'SHOW_CASH_MOVEMENT';
export const CASH_MOVEMENT_LOADING = 'CASH_MOVEMENT_LOADING';
export const CASH_MOVEMENT_VISIBLE = 'CASH_MOVEMENT_VISIBLE';
export const CASH_MOVEMENT_STATUS_CREATE = 'CASH_MOVEMENT_STATUS_CREATE';
export const CASH_MOVEMENT_STATUS_ACTIONS = 'CASH_MOVEMENT_STATUS_ACTIONS';
export const ADD_CASH_MOVEMENT = 'ADD_CASH_MOVEMENT';
export const DELETE_CASH_MOVEMENT = 'DELETE_CASH_MOVEMENT';
export const SHOW_EXPENSES_CASH_MOVEMENT = 'SHOW_EXPENSES_CASH_MOVEMENT';
export const SHOW_INPUTS = 'SHOW_INPUTS';
export const EXPENSE_GETINFO = 'EXPENSE_GETINFO';
export const GET_MOVEMENT = 'GET_MOVEMENT';
export const UPDATE_INPUT = 'UPDATE_INPUT';
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';

//Employee
export const LIST_EMPLOYEES = 'LIST_EMPLOYEES';
export const SHOW_EMPLOYEES = 'SHOW_EMPLOYEES';
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';
export const EMPLOYEE_VISIBLE = 'EMPLOYEE_VISIBLE';
export const EMPLOYEE_STATUS_CREATE = 'EMPLOYEE_STATUS_CREATE';
export const EMPLOYEE_STATUS_ACTIONS = 'EMPLOYEE_STATUS_ACTIONS';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const LIST_EMPLOYEE = 'LIST_EMPLOYEE';
export const ACTIVE_EMPLOYEE = 'ACTIVE_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const EMPLOYEE_UPDATE_PASSWORD = 'EMPLOYEE_UPDATE_PASSWORD';

//Cost Center
export const SHOW_COST_CENTERS = 'SHOW_COST_CENTERS';
export const COST_CENTER_LOADING = 'COST_CENTER_LOADING';
export const COST_CENTER_VISIBLE = 'COST_CENTER_VISIBLE';
export const COST_CENTER_STATUS_CREATE = 'COST_CENTER_STATUS_CREATE';
export const COST_CENTER_STATUS_ACTIONS = 'COST_CENTER_STATUS_ACTIONS';
export const ADD_COST_CENTER = 'ADD_COST_CENTER';
export const LIST_COST_CENTERS = 'LIST_COST_CENTERS';
export const ACTIVE_COST_CENTER = 'ACTIVE_COST_CENTER';
export const DELETE_COST_CENTER = 'DELETE_COST_CENTER';
export const GET_COST_CENTER = 'GET_COST_CENTER';
export const UPDATE_COST_CENTER = 'UPDATE_COST_CENTER';

//Measure Size
export const SHOW_MEASURE_SIZES = 'SHOW_MEASURE_SIZES';
export const MEASURE_SIZE_LOADING = 'MEASURE_SIZE_LOADING';
export const MEASURE_SIZE_VISIBLE = 'MEASURE_SIZE_VISIBLE';
export const MEASURE_SIZE_STATUS_CREATE = 'MEASURE_SIZE_STATUS_CREATE';
export const MEASURE_SIZE_STATUS_ACTIONS = 'MEASURE_SIZE_STATUS_ACTIONS';
export const ADD_MEASURE_SIZE = 'ADD_MEASURE_SIZE';
export const LIST_MEASURE_SIZES = 'LIST_MEASURE_SIZES';
export const ACTIVE_MEASURE_SIZE = 'ACTIVE_MEASURE_SIZE';
export const DELETE_MEASURE_SIZE = 'DELETE_MEASURE_SIZE';
export const GET_MEASURE_SIZE = 'GET_MEASURE_SIZE';
export const UPDATE_MEASURE_SIZE = 'UPDATE_MEASURE_SIZE';

//Provider
export const SHOW_PROVIDERS = 'SHOW_PROVIDERS';
export const PROVIDER_LOADING = 'PROVIDER_LOADING';
export const PROVIDER_VISIBLE = 'PROVIDER_VISIBLE';
export const PROVIDER_STATUS_CREATE = 'PROVIDER_STATUS_CREATE';
export const PROVIDER_STATUS_ACTIONS = 'PROVIDER_STATUS_ACTIONS';
export const ADD_PROVIDER = 'ADD_PROVIDER';
export const LIST_PROVIDERS = 'LIST_PROVIDERS';
export const DELETE_PROVIDER = 'DELETE_PROVIDER';
export const GET_PROVIDER = 'GET_PROVIDER';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';

//roles
export const LIST_ROLES = 'LIST_ROLES';

//Material
export const SHOW_MATERIALS = 'SHOW_MATERIALS';
export const ACTIVE_MATERIAL = 'ACTIVE_MATERIAL';
export const MATERIAL_STATUS_ACTIONS = 'MATERIAL_STATUS_ACTIONS';
export const MATERIAL_STATUS_CREATE = 'MATERIAL_STATUS_CREATE';
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const DELETE_MATERIAL = 'DELETE_MATERIAL';
export const GET_MATERIAL = 'GET_MATERIAL';
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const MATERIAL_LOADING = 'MATERIAL_LOADING';
export const MATERIAL_VISIBLE = 'MATERIAL_VISIBLE';

//UNIT
export const SHOW_UNITS = 'SHOW_UNITS';
export const UNIT_LOADING = 'UNIT_LOADING';
export const UNIT_VISIBLE = 'UNIT_VISIBLE';
export const UNIT_STATUS_CREATE = 'UNIT_STATUS_CREATE';
export const UNIT_STATUS_ACTIONS = 'UNIT_STATUS_ACTIONS';
export const ADD_UNIT = 'ADD_UNIT';
export const LIST_UNITS = 'LIST_UNITS';
export const DELETE_UNIT = 'DELETE_UNIT';
export const GET_UNIT = 'GET_UNIT';
export const UPDATE_UNIT = 'UPDATE_UNIT';
export const ACTIVE_UNIT = 'ACTIVE_UNIT';

//STORAGE
export const SHOW_STORAGES = 'SHOW_STORAGES';
export const STORAGE_LOADING = 'STORAGE_LOADING';
export const STORAGE_VISIBLE = 'STORAGE_VISIBLE';
export const STORAGE_STATUS_CREATE = 'STORAGE_STATUS_CREATE';
export const STORAGE_STATUS_ACTIONS = 'STORAGE_STATUS_ACTIONS';
export const ADD_STORAGE = 'ADD_STORAGE';
export const LIST_STORAGES = 'LIST_STORAGES';
export const DELETE_STORAGE = 'DELETE_STORAGE';
export const GET_STORAGE = 'GET_STORAGE';
export const UPDATE_STORAGE = 'UPDATE_STORAGE';
export const ACTIVE_STORAGE = 'ACTIVE_STORAGE';

//ORDER
export const SHOW_ORDERS = 'SHOW_ORDERS';
export const LIST_ORDER = 'LIST_ORDER';
export const ACTIVE_ORDER = 'ACTIVE_ORDER';
export const ORDER_STATUS_ACTIONS = 'ORDER_STATUS_ACTIONS';
export const ORDER_STATUS_CREATE = 'ORDER_STATUS_CREATE';
export const ADD_ORDER = 'ADD_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const GET_ORDER = 'GET_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_VISIBLE = 'ORDER_VISIBLE';

//PRODUCT
export const SHOW_PRODUCTS = 'SHOW_PRODUCTS';
export const ACTIVE_PRODUCT = 'ACTIVE_PRODUCT';
export const PRODUCT_STATUS_ACTIONS = 'PRODUCT_STATUS_ACTIONS';
export const PRODUCT_STATUS_CREATE = 'PRODUCT_STATUS_CREATE';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_VISIBLE = 'PRODUCT_VISIBLE';
export const LIST_PRODUCT = 'LIST_PRODUCT';


//PURCHASEORDER
export const SHOW_PURCHASEORDERS = 'SHOW_PURCHASEORDERS';
export const ACTIVE_PURCHASEORDER = 'ACTIVE_PURCHASEORDER';
export const PURCHASEORDER_STATUS_ACTIONS = 'PURCHASEORDER_STATUS_ACTIONS';
export const PURCHASEORDER_STATUS_CREATE = 'PURCHASEORDER_STATUS_CREATE';
export const ADD_PURCHASEORDER = 'ADD_PURCHASEORDER';
export const DELETE_PURCHASEORDER = 'DELETE_PURCHASEORDER';
export const GET_PURCHASEORDER = 'GET_PURCHASEORDER';
export const UPDATE_PURCHASEORDER = 'UPDATE_PURCHASEORDER';
export const PURCHASEORDER_LOADING = 'PURCHASEORDER_LOADING';
export const PURCHASEORDER_VISIBLE = 'PURCHASEORDER_VISIBLE';


//MATERIAL_MOVEMENT
export const SHOW_MATERIAL_MOVEMENT = 'SHOW_MATERIAL_MOVEMENT';


//MaterialRegister
export const SHOW_MATERIAL_REGISTERS = 'SHOW_MATERIAL_REGISTERS';
export const ACTIVE_MATERIAL_REGISTER = 'ACTIVE_MATERIAL_REGISTER';
export const MATERIAL_REGISTER_STATUS_ACTIONS = 'MATERIAL_REGISTER_STATUS_ACTIONS';
export const MATERIAL_REGISTER_STATUS_CREATE = 'MATERIAL_REGISTER_STATUS_CREATE';
export const ADD_MATERIAL_REGISTER = 'ADD_MATERIAL_REGISTER';
export const DELETE_MATERIAL_REGISTER = 'DELETE_MATERIAL_REGISTER';
export const GET_MATERIAL_REGISTER = 'GET_MATERIAL_REGISTER';
export const UPDATE_MATERIAL_REGISTER = 'UPDATE_MATERIAL_REGISTER';
export const MATERIAL_REGISTER_LOADING = 'MATERIAL_REGISTER_LOADING';
export const MATERIAL_REGISTER_VISIBLE = 'MATERIAL_VISIBLE';


//CLIENT
export const SHOW_CLIENTS = 'SHOW_CLIENTS';
export const CLIENT_LOADING = 'CLIENT_LOADING';
export const CLIENT_VISIBLE = 'CLIENT_VISIBLE';
export const CLIENT_STATUS_CREATE = 'CLIENT_STATUS_CREATE';
export const CLIENT_STATUS_ACTIONS = 'CLIENT_STATUS_ACTIONS';
export const ADD_CLIENT = 'ADD_CLIENT';
export const LIST_CLIENTS = 'LIST_CLIENTS';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const GET_CLIENT = 'GET_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';

//Order Consumption
export const SHOW_ORDER_CONSUMPTIONS = 'SHOW_ORDER_CONSUMPTIONS';
export const ACTIVE_ORDER_CONSUMPTION = 'ACTIVE_ORDER_CONSUMPTION';
export const ORDER_CONSUMPTION_STATUS_ACTIONS = 'ORDER_CONSUMPTION_STATUS_ACTIONS';
export const ORDER_CONSUMPTION_STATUS_CREATE = 'ORDER_CONSUMPTION_STATUS_CREATE';
export const ADD_ORDER_CONSUMPTION = 'ADD_ORDER_CONSUMPTION';
export const DELETE_ORDER_CONSUMPTION = 'DELETE_ORDER_CONSUMPTION';
export const GET_ORDER_CONSUMPTION = 'GET_ORDER_CONSUMPTION';
export const UPDATE_ORDER_CONSUMPTION = 'UPDATE_ORDER_CONSUMPTION';
export const ORDER_CONSUMPTION_LOADING = 'ORDER_CONSUMPTION_LOADING';
export const ORDER_CONSUMPTION_VISIBLE = 'ORDER_CONSUMPTION_VISIBLE';

//PRODUCT FINISHED
export const SHOW_PRODUCT_FINISHED = 'SHOW_PRODUCT_FINISHED';
export const ACTIVE_PRODUCT_FINISHED = 'ACTIVE_PRODUCT_FINISHED';
export const PRODUCT_FINISHED_STATUS_ACTIONS = 'PRODUCT_FINISHED_STATUS_ACTIONS';
export const PRODUCT_FINISHED_STATUS_CREATE = 'PRODUCT_FINISHED_STATUS_CREATE';
export const ADD_PRODUCT_FINISHED = 'ADD_PRODUCT_FINISHED';
export const DELETE_PRODUCT_FINISHED = 'DELETE_PRODUCT_FINISHED';
export const GET_PRODUCT_FINISHED = 'GET_PRODUCT_FINISHED';
export const UPDATE_PRODUCT_FINISHED = 'UPDATE_PRODUCT_FINISHED';
export const PRODUCT_FINISHED_LOADING = 'PRODUCT_FINISHED_LOADING';
export const PRODUCT_FINISHED_VISIBLE = 'PRODUCT_FINISHED_VISIBLE';

//PRODUCT_MOVEMENT
export const SHOW_PRODUCT_MOVEMENT = 'SHOW_PRODUCT_MOVEMENT';

//PRODUCT_LOT
export const SHOW_PRODUCT_LOT = 'SHOW_PRODUCT_LOT';
export const GET_PRODUCT_LOT = 'GET_PRODUCT_LOT';
export const PRODUCT_LOT_VISIBLE = 'PRODUCT_LOT_VISIBLE';


//GUIDE_REFERRAL
export const SHOW_GUIDE_REFERRAL = 'SHOW_GUIDE_REFERRAL';
export const ACTIVE_GUIDE_REFERRAL = 'ACTIVE_GUIDE_REFERRAL';
export const GUIDE_REFERRAL_STATUS_ACTIONS = 'GUIDE_REFERRAL_STATUS_ACTIONS';
export const GUIDE_REFERRAL_STATUS_CREATE = 'GUIDE_REFERRAL_STATUS_CREATE';
export const ADD_GUIDE_REFERRAL = 'ADD_GUIDE_REFERRAL';
export const DELETE_GUIDE_REFERRAL = 'DELETE_GUIDE_REFERRAL';
export const GET_GUIDE_REFERRAL = 'GET_GUIDE_REFERRAL';
export const UPDATE_GUIDE_REFERRAL = 'UPDATE_GUIDE_REFERRAL';
export const GUIDE_REFERRAL_LOADING = 'GUIDE_REFERRAL_LOADING';
export const GUIDE_REFERRAL_VISIBLE = 'GUIDE_REFERRAL_VISIBLE';
export const LIST_GUIDE_REFERRAL = 'LIST_GUIDE_REFERRAL';
//PRODUCT SEMIFINISHED
export const SHOW_PRODUCT_SEMIFINISHED = 'SHOW_PRODUCT_SEMIFINISHED';
export const ACTIVE_PRODUCT_SEMIFINISHED = 'ACTIVE_PRODUCT_SEMIFINISHED';
export const PRODUCT_SEMIFINISHED_STATUS_ACTIONS = 'PRODUCT_SEMIFINISHED_STATUS_ACTIONS';
export const PRODUCT_SEMIFINISHED_STATUS_CREATE = 'PRODUCT_SEMIFINISHED_STATUS_CREATE';
export const ADD_PRODUCT_SEMIFINISHED = 'ADD_PRODUCT_SEMIFINISHED';
export const DELETE_PRODUCT_SEMIFINISHED = 'DELETE_PRODUCT_SEMIFINISHED';
export const GET_PRODUCT_SEMIFINISHED = 'GET_PRODUCT_SEMIFINISHED';
export const UPDATE_PRODUCT_SEMIFINISHED = 'UPDATE_PRODUCT_SEMIFINISHED';
export const PRODUCT_SEMIFINISHED_LOADING = 'PRODUCT_SEMIFINISHED_LOADING';
export const PRODUCT_SEMIFINISHED_VISIBLE = 'PRODUCT_SEMIFINISHED_VISIBLE';


//CUT
export const SHOW_CUT = 'SHOW_CUT';
export const ACTIVE_CUT = 'ACTIVE_CUT';
export const CUT_STATUS_ACTIONS = 'CUT_STATUS_ACTIONS';
export const CUT_STATUS_CREATE = 'CUT_STATUS_CREATE';
export const ADD_CUT = 'ADD_CUT';
export const DELETE_CUT = 'DELETE_CUT';
export const GET_CUT = 'GET_CUT';
export const UPDATE_CUT = 'UPDATE_CUT';
export const CUT_LOADING = 'CUT_LOADING';
export const CUT_VISIBLE = 'CUT_VISIBLE';

//PRODUCT_SEMIFINISHED_MOVEMENT
export const SHOW_PRODUCT_SEMIFINISHED_MOVEMENT = 'SHOW_PRODUCT_SEMIFINISHED_MOVEMENT';

//PRODUCT_SEMIFINISHED_LOT
export const SHOW_PRODUCT_SEMIFINISHED_LOT = 'SHOW_PRODUCT_SEMIFINISHED_LOT';
export const GET_PRODUCT_SEMIFINISHED_LOT = 'GET_PRODUCT_SEMIFINISHED_LOT';
export const PRODUCT_SEMIFINISHED_LOT_VISIBLE = 'PRODUCT_SEMIFINISHED_LOT_VISIBLE';


//PURCHASE_ORDER_GENERAL
export const SHOW_PURCHASE_ORDER_GENERAL = 'SHOW_PURCHASE_ORDER_GENERAL';
export const ACTIVE_PURCHASE_ORDER_GENERAL = 'ACTIVE_PURCHASE_ORDER_GENERAL';
export const PURCHASE_ORDER_GENERAL_STATUS_ACTIONS = 'PURCHASE_ORDER_GENERAL_STATUS_ACTIONS';
export const PURCHASE_ORDER_GENERAL_STATUS_CREATE = 'PURCHASE_ORDER_GENERAL_STATUS_CREATE';
export const ADD_PURCHASE_ORDER_GENERAL = 'ADD_PURCHASE_ORDER_GENERAL';
export const DELETE_PURCHASE_ORDER_GENERAL = 'DELETE_PURCHASE_ORDER_GENERAL';
export const GET_PURCHASE_ORDER_GENERAL = 'GET_PURCHASE_ORDER_GENERAL';
export const UPDATE_PURCHASE_ORDER_GENERAL = 'UPDATE_PURCHASE_ORDER_GENERAL';
export const PURCHASE_ORDER_GENERAL_LOADING = 'PURCHASE_ORDER_GENERAL_LOADING';
export const PURCHASE_ORDER_GENERAL_VISIBLE = 'PURCHASE_ORDER_GENERAL_VISIBLE';
export const LIST_PURCHASE_ORDER_GENERAL = 'LIST_PURCHASE_ORDER_GENERAL';



//THIRD
export const SHOW_THIRD = 'SHOW_THIRD';
export const THIRD_LOADING = 'THIRD_LOADING';
export const THIRD_VISIBLE = 'THIRD_VISIBLE';
export const THIRD_STATUS_CREATE = 'THIRD_STATUS_CREATE';
export const THIRD_STATUS_ACTIONS = 'THIRD_STATUS_ACTIONS';
export const ADD_THIRD = 'ADD_THIRD';
export const LIST_THIRD = 'LIST_THIRD';
export const DELETE_THIRD = 'DELETE_THIRD';
export const GET_THIRD = 'GET_THIRD';
export const UPDATE_THIRD = 'UPDATE_THIRD';