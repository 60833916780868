import {
    SHOW_PRODUCT_MOVEMENT
} from "../../constants/ActionTypes";

const INIT_STATE = {
    productMovementList: [],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    }
    
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_PRODUCT_MOVEMENT: {
            return {
                ...state,
                productMovementList: action.payload
            }
        }

      

      default:
        return state;
    }
  }
  