import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
  SHOW_ORDER_CONSUMPTIONS,
  ACTIVE_ORDER_CONSUMPTION,
  ORDER_CONSUMPTION_STATUS_ACTIONS,
  ORDER_CONSUMPTION_STATUS_CREATE,
  ORDER_CONSUMPTION_LOADING,
  ORDER_CONSUMPTION_VISIBLE,
  GET_ORDER_CONSUMPTION,
  UPDATE_ORDER_CONSUMPTION,
  DELETE_ORDER_CONSUMPTION,
  ADD_ORDER_CONSUMPTION
} from "../../constants/ActionTypes";

const INIT_STATE = {
  orderConsumptionList: [],
  orderConsumptionListSelect: [],
  meta: {
    totalData: 0,
    pageSize: 0,
    current: 0,
    totalPages: 0,
  },
  orderConsumptionObject: [],
  orderConsumptionStatusCreate: 0,
  orderConsumptionStatusActions: 0,
  loading: false,
  visible: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_ORDER_CONSUMPTIONS: {
      return {
        ...state,
        orderConsumptionList: action.payload,
       
      };
    }
    case ACTIVE_ORDER_CONSUMPTION: {
      return {
          ...state
      }
    }

    case ORDER_CONSUMPTION_STATUS_ACTIONS: {
      return {
          ...state,
          orderConsumptionStatusActions:action.payload
      }
    }
    case ORDER_CONSUMPTION_STATUS_CREATE: {
      return {
          ...state,
          orderConsumptionStatusCreate:action.payload
      }
    }
    case GET_ORDER_CONSUMPTION: {
      return {
          ...state,
          orderConsumptionObject : action.payload
      }
  }

  case ORDER_CONSUMPTION_LOADING: {
    return {
    ...state,
    loading: action.payload,
    }
  }
  case ORDER_CONSUMPTION_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
  }
  case UPDATE_ORDER_CONSUMPTION: {
    return {
        ...state
    }
  }
  case ADD_ORDER_CONSUMPTION: {
    return {
        ...state
    }
  }
  case DELETE_ORDER_CONSUMPTION: {
    return {
        ...state
    }
  }
   default:
      return state;
  }


};
