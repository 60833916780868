import {
  LIST_DETAIL_BY_KEY,
  SHOW_LIST_DETAIL,
  LIST_DETAIL_STATUS_ACTIONS,
  LIST_DETAIL_STATUS_CREATE,
  LIST_DETAIL_LOADING,
  LIST_DETAIL_VISIBLE,
  ADD_LIST_DETAIL,
  GET_LIST_DETAIL,
  DELETE_LIST_DETAIL,
  UPDATE_LIST_DETAIL,
  LIST_DETAIL_TYPE_OPERATION
} from "../../constants/ActionTypes";

const INIT_STATE = {
  detailList: [],
  typeOperationList:[],
  pageList: [],
  meta: {
    totalData: 0,
    pageSize: 0,
    current: 0,
    totalPages: 0,
  },
  listDetailObject: [],
  listDetailStatusCreate: 0,
  listDetailStatusActions: 0,
  loading: false,
  visible: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_LIST_DETAIL: {
      return {
        ...state,
        pageList: action.payload
      };
    }
    case LIST_DETAIL_TYPE_OPERATION: {
      return {
        ...state,
        typeOperationList: action.payload,
      };
    }
    case LIST_DETAIL_BY_KEY: {
      return {
        ...state,
        detailList: action.payload,
      };
    }

    case LIST_DETAIL_STATUS_ACTIONS: {
      return {
        ...state,
        listDetailStatusActions: action.payload,
      };
    }

    case LIST_DETAIL_STATUS_CREATE: {
      return {
        ...state,
        listDetailStatusCreate: action.payload,
      };
    }

    case LIST_DETAIL_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case LIST_DETAIL_VISIBLE: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    case ADD_LIST_DETAIL: {
      return {
        ...state,
      };
    }

    case GET_LIST_DETAIL: {
      return {
        ...state,
        listDetailObject: action.payload,
      };
    }

    case DELETE_LIST_DETAIL: {
      return {
        ...state,
      };
    }

    case UPDATE_LIST_DETAIL: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
