import {
    SHOW_PROVIDERS,
    PROVIDER_LOADING,
    PROVIDER_VISIBLE,
    PROVIDER_STATUS_CREATE,
    PROVIDER_STATUS_ACTIONS,
    ADD_PROVIDER,
    LIST_PROVIDERS,
    DELETE_PROVIDER,
    GET_PROVIDER,
    UPDATE_PROVIDER,

} from "../../constants/ActionTypes";

const INIT_STATE = {
    providerList: [],
    providerListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    providerObject: [],
    providerStatusCreate: 0,
    providerStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_PROVIDERS: {
            return {
                ...state,
                providerList: action.payload
            }
        }

        case PROVIDER_STATUS_ACTIONS: {
            return {
                ...state,
                providerStatusActions:action.payload
            }
        }
        
        case PROVIDER_STATUS_CREATE: {
            return {
                ...state,
                providerStatusCreate:action.payload
            }
        }

        case PROVIDER_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case PROVIDER_VISIBLE: {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_PROVIDER: {
            return {
                ...state
            }
        }
      
        case GET_PROVIDER: {
            return {
                ...state,
                providerObject : action.payload
            }
        }

        case LIST_PROVIDERS: {
            return {
                ...state,
                providerListSelect: action.payload,
            }
        }      

        case DELETE_PROVIDER: {
            return {
                ...state
            }
        }


        case UPDATE_PROVIDER: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  