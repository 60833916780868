import {
    SHOW_CASH_MOVEMENT,
    CASH_MOVEMENT_LOADING,
    CASH_MOVEMENT_VISIBLE,
    CASH_MOVEMENT_STATUS_CREATE,
    CASH_MOVEMENT_STATUS_ACTIONS,
    ADD_CASH_MOVEMENT,
    DELETE_CASH_MOVEMENT,
    SHOW_EXPENSES_CASH_MOVEMENT,
    EXPENSE_GETINFO,   
    SHOW_INPUTS,
    GET_MOVEMENT, 
    UPDATE_INPUT, 
    UPDATE_EXPENSE, 

} from "../../constants/ActionTypes";

const INIT_STATE = {
    cashMovementList: [],
    inputsList: [],
    metaInput:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    cashMovementListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    expensesList: [],
    metaExpenses:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    cashMovementObject: [],
    expenseInfoData:[],
    cashMovementStatusCreate: 0,
    cashMovementStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_CASH_MOVEMENT: {
            return {
                ...state,
                cashMovementList: action.payload
            }
        }

        case SHOW_INPUTS: {
            return {
                ...state,
                inputsList: action.payload
            }
        }

        case CASH_MOVEMENT_STATUS_ACTIONS: {
            return {
                ...state,
                cashMovementStatusActions:action.payload
            }
        }
        case CASH_MOVEMENT_STATUS_CREATE: {
            return {
                ...state,
                cashStatusCreate:action.payload
            }
        }

        case CASH_MOVEMENT_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case CASH_MOVEMENT_VISIBLE: {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_CASH_MOVEMENT: {
            return {
                ...state
            }
        }

        case DELETE_CASH_MOVEMENT: {
            return {
                ...state
            }
        }

        case SHOW_EXPENSES_CASH_MOVEMENT: {
            return {
                ...state,
                expensesList: action.payload
            }
        }

        case EXPENSE_GETINFO: {
            return {
            ...state,
            expenseInfoData: action.payload,
            }
        }

        case GET_MOVEMENT: {
            return {
                ...state,
                cashMovementObject : action.payload
            }
        }

        case UPDATE_INPUT: {
            return {
                ...state
            }
        }

        case UPDATE_EXPENSE: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  