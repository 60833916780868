import {
    SHOW_CLIENTS,
    CLIENT_LOADING,
    CLIENT_VISIBLE,
    CLIENT_STATUS_CREATE,
    CLIENT_STATUS_ACTIONS,
    ADD_CLIENT,
    LIST_CLIENTS,
    DELETE_CLIENT,
    GET_CLIENT,
    UPDATE_CLIENT,

} from "../../constants/ActionTypes";

const INIT_STATE = {
    clientList: [],
    clientListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    clientObject: [],
    clientStatusCreate: 0,
    clientStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_CLIENTS: {
            return {
                ...state,
                clientList: action.payload
            }
        }

        case CLIENT_STATUS_ACTIONS: {
            return {
                ...state,
                clientStatusActions:action.payload
            }
        }
        
        case CLIENT_STATUS_CREATE: {
            return {
                ...state,
                clientStatusCreate:action.payload
            }
        }

        case CLIENT_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case CLIENT_VISIBLE: {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_CLIENT: {
            return {
                ...state
            }
        }
      
        case GET_CLIENT: {
            return {
                ...state,
                clientObject : action.payload
            }
        }

        case LIST_CLIENTS: {
            return {
                ...state,
                clientListSelect: action.payload,
            }
        }      

        case DELETE_CLIENT: {
            return {
                ...state
            }
        }


        case UPDATE_CLIENT: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  