import {
    SHOW_UNITS,
    UNIT_LOADING,
    UNIT_VISIBLE,
    UNIT_STATUS_CREATE,
    UNIT_STATUS_ACTIONS,
    ADD_UNIT,
    LIST_UNITS,
    DELETE_UNIT,
    GET_UNIT,
    UPDATE_UNIT,
    ACTIVE_UNIT

} from "../../constants/ActionTypes";

const INIT_STATE = {
    unitList: [],
    unitListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    unitObject: [],
    unitStatusCreate: 0,
    unitStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_UNITS: {
            return {
                ...state,
                unitList: action.payload
            }
        }

        case UNIT_STATUS_ACTIONS: {
            return {
                ...state,
                unitStatusActions:action.payload
            }
        }
        
        case UNIT_STATUS_CREATE: {
            return {
                ...state,
                unitStatusCreate:action.payload
            }
        }

        case UNIT_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case UNIT_VISIBLE: {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_UNIT: {
            return {
                ...state
            }
        }
      
        case GET_UNIT: {
            return {
                ...state,
                unitObject : action.payload
            }
        }

        case LIST_UNITS: {
            return {
                ...state,
                unitListSelect: action.payload,
            }
        }   
        case ACTIVE_UNIT: {
            return {
                ...state
            }
        }
   

        case DELETE_UNIT: {
            return {
                ...state
            }
        }


        case UPDATE_UNIT: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  