import {
  SHOW_MATERIALS,
  ACTIVE_MATERIAL,
  MATERIAL_STATUS_ACTIONS,
  MATERIAL_STATUS_CREATE,
  MATERIAL_LOADING,
  MATERIAL_VISIBLE,
  GET_MATERIAL,
  UPDATE_MATERIAL,
  ADD_MATERIAL,
  DELETE_MATERIAL
} from "../../constants/ActionTypes";

const INIT_STATE = {
  materialList: [],
  materialListSelect: [],
  meta: {
    totalData: 0,
    pageSize: 0,
    current: 0,
    totalPages: 0,
  },
  materialObject: [],
  materialStatusCreate: 0,
  materialStatusActions: 0,
  loading: false,
  visible: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_MATERIALS: {
      return {
        ...state,
        materialList: action.payload
      };
    }
    case ACTIVE_MATERIAL: {
      return {
          ...state
      }
    }

    case MATERIAL_STATUS_ACTIONS: {
      return {
          ...state,
          materialStatusActions:action.payload
      }
    }
    case MATERIAL_STATUS_CREATE: {
      return {
          ...state,
          materialStatusCreate:action.payload
      }
    }
    case GET_MATERIAL: {
      return {
          ...state,
          materialObject : action.payload
      }
  }
  case MATERIAL_LOADING: {
    return {
    ...state,
    loading: action.payload,
    }
  }
  case MATERIAL_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
  }
  case UPDATE_MATERIAL: {
    return {
        ...state
    }
  }
  case ADD_MATERIAL: {
    return {
        ...state
    }
  }
  case DELETE_MATERIAL: {
    return {
        ...state
    }
  }
   default:
      return state;
  }


};
