import {
    SHOW_MEASURE_SIZES,
    MEASURE_SIZE_LOADING,
    MEASURE_SIZE_VISIBLE,
    MEASURE_SIZE_STATUS_CREATE,
    MEASURE_SIZE_STATUS_ACTIONS,
    ADD_MEASURE_SIZE,
    LIST_MEASURE_SIZES,
    ACTIVE_MEASURE_SIZE,
    DELETE_MEASURE_SIZE,
    GET_MEASURE_SIZE,
    UPDATE_MEASURE_SIZE,

} from "../../constants/ActionTypes";

const INIT_STATE = {
    measureSizeList: [],
    measureSizeListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    measureSizeObject: [],
    measureSizeStatusCreate: 0,
    measureSizeStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_MEASURE_SIZES: {
            return {
                ...state,
                measureSizeList: action.payload
            }
        }

        case MEASURE_SIZE_STATUS_ACTIONS: {
            return {
                ...state,
                measureSizeStatusActions:action.payload
            }
        }
        
        case MEASURE_SIZE_STATUS_CREATE: {
            return {
                ...state,
                measureSizeStatusCreate:action.payload
            }
        }

        case MEASURE_SIZE_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case MEASURE_SIZE_VISIBLE: {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_MEASURE_SIZE: {
            return {
                ...state
            }
        }
      
        case GET_MEASURE_SIZE: {
            return {
                ...state,
                measureSizeObject : action.payload
            }
        }

        case LIST_MEASURE_SIZES: {
            return {
                ...state,
                measureSizeListSelect: action.payload,
            }
        }      

        case DELETE_MEASURE_SIZE: {
            return {
                ...state
            }
        }

        case ACTIVE_MEASURE_SIZE: {
            return {
                ...state
            }
        }

        case UPDATE_MEASURE_SIZE: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  