import {
    SHOW_MATERIAL_MOVEMENT,

} from "../../constants/ActionTypes";

const INIT_STATE = {
    materialMovementList: [],
  
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_MATERIAL_MOVEMENT: {
            return {
                ...state,
                materialMovementList: action.payload
            }
        }

      

      default:
        return state;
    }
  }
  