import React from "react";
import {Route, Switch} from "react-router-dom";

import Base from "./base/index";
import Storage from "./storage/index";
import Order from "./order/index";

import CashManagement from "./cashManagement/index";
import asyncComponent from "../util/asyncComponent";
import InfoView from "../components/InfoView";
const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}base`} component={Base}/>
      <Route path={`${match.url}Order`} component={Order}/>

      <Route path={`${match.url}cash-management`} component={CashManagement}/>
      <Route path={`${match.url}storage`} component={Storage}/>
      <Route path={`${match.url}user`} component={asyncComponent(() => import('./User'))}/>

    </Switch>
    <InfoView/>

  </div>
);

export default App;
