import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";


const Order = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/order`}/>
    <Route path={`${match.url}/order`} component={asyncComponent(() => import('./Order'))}/>
    <Route path={`${match.url}/purchase-order`} component={asyncComponent(() => import('./PurchaseOrder'))}/>
    <Route path={`${match.url}/order-consumption`} component={asyncComponent(() => import('./OrderConsumption'))}/>
    <Route path={`${match.url}/guide-referral`} component={asyncComponent(() => import('./GuideReferral'))}/>
    <Route path={`${match.url}/purchase-order-general`} component={asyncComponent(() => import('./PurchaseOrderGeneral'))}/>




  </Switch>
);

export default Order;
