import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";


const Storage = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/material`}/>
    <Route path={`${match.url}/material`} component={asyncComponent(() => import('./Material'))}/>
    <Route path={`${match.url}/storage`} component={asyncComponent(() => import('./Storage'))}/>
    <Route path={`${match.url}/unit`} component={asyncComponent(() => import('./Unit'))}/>
    <Route path={`${match.url}/product`} component={asyncComponent(() => import('./Product'))}/>
    <Route path={`${match.url}/product-finished`} component={asyncComponent(() => import('./ProductFinished'))}/>

    <Route path={`${match.url}/product`} component={asyncComponent(() => import('./Product'))} />
    <Route path={`${match.url}/productsemifinished`} component={asyncComponent(() => import('./Productsemifinished'))}/>

    <Route path={`${match.url}/cut`} component={asyncComponent(() => import('./Cut'))}/>

  </Switch>
);

export default Storage;
