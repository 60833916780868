import {
    SHOW_STORAGES,
    STORAGE_LOADING,
    STORAGE_VISIBLE,
    STORAGE_STATUS_CREATE,
    STORAGE_STATUS_ACTIONS,
    ADD_STORAGE,
    LIST_STORAGES,
    DELETE_STORAGE,
    GET_STORAGE,
    UPDATE_STORAGE,
    ACTIVE_STORAGE

} from "../../constants/ActionTypes";

const INIT_STATE = {
    storageList: [],
    storageListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    storageObject: [],
    storageStatusCreate: 0,
    storageStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_STORAGES: {
            return {
                ...state,
                storageList: action.payload
            }
        }

        case STORAGE_STATUS_ACTIONS: {
            return {
                ...state,
                storageStatusActions:action.payload
            }
        }
        
        case STORAGE_STATUS_CREATE: {
            return {
                ...state,
                storageStatusCreate:action.payload
            }
        }

        case STORAGE_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case STORAGE_VISIBLE: {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_STORAGE: {
            return {
                ...state
            }
        }
      
        case GET_STORAGE: {
            return {
                ...state,
                storageObject : action.payload
            }
        }

        case LIST_STORAGES: {
            return {
                ...state,
                storageListSelect: action.payload,
            }
        }   
        case ACTIVE_STORAGE: {
            return {
                ...state
            }
        }
   

        case DELETE_STORAGE: {
            return {
                ...state
            }
        }


        case UPDATE_STORAGE: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  