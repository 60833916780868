import React from "react";
import {useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import UserPNG from "../../assets/images/user.png";
const UserRole = () => {

  const current_user = useSelector(store => store.auth.authUser);

  

  return (
    <>
        <span>Usuario: {current_user? current_user.fullName : '-'} / Perfil: {current_user? current_user.role : '-'} </span>
    </>
  )

}

export default UserRole;
