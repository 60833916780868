import {
    SHOW_GUIDE_REFERRAL,
    ACTIVE_GUIDE_REFERRAL,
    GUIDE_REFERRAL_STATUS_ACTIONS,
    GUIDE_REFERRAL_STATUS_CREATE,
    GUIDE_REFERRAL_LOADING,
    GUIDE_REFERRAL_VISIBLE,
    GET_GUIDE_REFERRAL,
    UPDATE_GUIDE_REFERRAL,
    ADD_GUIDE_REFERRAL,
    DELETE_GUIDE_REFERRAL,
    LIST_GUIDE_REFERRAL
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    guideReferralList: [],
    guideReferralListSelect: [],
    meta: {
      totalData: 0,
      pageSize: 0,
      current: 0,
      totalPages: 0,
    },
    guideReferralObject: [],
   
    guideReferralStatusCreate: 0,
    guideReferralStatusActions: 0,
    loading: false,
    visible: false,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case SHOW_GUIDE_REFERRAL: {
        return {
          ...state,
          guideReferralList: action.payload.data,
          meta: {
            totalData: action.payload.meta.total,
            pageSize: action.payload.meta.per_page,
            current: action.payload.meta.current_page,
            totalPages: action.payload.meta.last_page,
          },
        };
      }
      case ACTIVE_GUIDE_REFERRAL: {
        return {
            ...state
        }
      }
  
      case GUIDE_REFERRAL_STATUS_ACTIONS: {
        return {
            ...state,
            guideReferralStatusActions:action.payload
        }
      }
      case GUIDE_REFERRAL_STATUS_CREATE: {
        return {
            ...state,
            guideReferralStatusCreate:action.payload
        }
      }
      case GET_GUIDE_REFERRAL: {
        return {
            ...state,
            guideReferralObject : action.payload
        }
    }
 
    case GUIDE_REFERRAL_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
    }
    case GUIDE_REFERRAL_VISIBLE: {
        return {
        ...state,
        visible: action.payload,
        }
    }
    case UPDATE_GUIDE_REFERRAL: {
      return {
          ...state
      }
    }
    case ADD_GUIDE_REFERRAL: {
      return {
          ...state
      }
    }
    case DELETE_GUIDE_REFERRAL: {
      return {
          ...state
      }
    }
    case LIST_GUIDE_REFERRAL: {
      return {
          ...state,
          guideReferralList: action.payload,
      }
  } 
     default:
        return state;
    }
  
  
  };
  