import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
  SHOW_CUT,
  ACTIVE_CUT,
  CUT_STATUS_ACTIONS,
  CUT_STATUS_CREATE,
  CUT_LOADING,
  CUT_VISIBLE,
  GET_CUT,
  UPDATE_CUT,
  DELETE_CUT,
  ADD_CUT
} from "../../constants/ActionTypes";

const INIT_STATE = {
  cutList: [],
  cutListSelect: [],
  meta: {
    totalData: 0,
    pageSize: 0,
    current: 0,
    totalPages: 0,
  },
  cutObject: [],

  cutStatusCreate: 0,
  cutStatusActions: 0,
  loading: false,
  visible: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case SHOW_CUT: {
      return {
        ...state,
       cutList: action.payload.data,
        meta: {
          totalData: action.payload.meta.total,
          pageSize: action.payload.meta.per_page,
          current: action.payload.meta.current_page,
          totalPages: action.payload.meta.last_page,
        },
      };
    }

    case ACTIVE_CUT: {
      return {
        ...state
      }
    }

    case CUT_STATUS_ACTIONS: {
      return {
        ...state,
        cutStatusActions: action.payload
      }
    }
    case CUT_STATUS_CREATE: {
      return {
        ...state,
        cutStatusCreate: action.payload
      }
    }
    case GET_CUT: {
      return {
        ...state,
        cutObject: action.payload
      }
    }

    case CUT_LOADING: {
      return {
        ...state,
        loading: action.payload,
      }
    }
    case CUT_VISIBLE: {
      return {
        ...state,
        visible: action.payload,
      }
    }
    case UPDATE_CUT: {
      return {
        ...state
      }
    }
    case ADD_CUT: {
      return {
        ...state
      }
    }
    case DELETE_CUT: {
      return {
        ...state
      }
    }
    default:
      return state;
  }


};
