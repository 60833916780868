import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";


const CashManagement = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/cash`}/>
    <Route path={`${match.url}/cash`} component={asyncComponent(() => import('./Cash'))}/>
    <Route path={`${match.url}/expense-report`} component={asyncComponent(() => import('./ExpenseReport'))}/>
    <Route path={`${match.url}/report`} component={asyncComponent(() => import('./Report'))}/>
  </Switch>
);

export default CashManagement;
