import {combineReducers} from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Cash from "./Cash";
import CashMovement from "./CashMovement";
import Employee from "./Employee";
import CostCenter from "./CostCenter";
import MeasureSize from "./MeasureSize";
import Provider from "./Provider";
import Client from "./Client";

import Role from "./Role";
import ListDetail from "./ListDetail";
import Material from "./Material";
import Unit from "./Unit";
import Storage from "./Storage";
import MaterialMovement from "./MaterialMovement";

import Common from "./Common";
import {connectRouter} from 'connected-react-router'
import Order from "./Order";
import Product from "./Product";
import PurchaseOrder from "./PurchaseOrder";
import MaterialRegister from "./MaterialRegister";
import OrderConsumption from "./OrderConsumption";
import ProductFinished from "./ProductFinished";
import ProductMovement from "./ProductMovement ";
import ProductLot from "./ProductLot";
import GuideReferral from "./GuideReferral";

import ProductSemifinished from "./ProductSemifinished";
import Cut from "./Cut";
import ProductSemifinishedMovement from "./ProductSemifinishedMovement";
import ProductSemifinishedLot from "./ProductSemifinishedLot";
import PurchaseOrderGeneral from "./PurchaseOrderGeneral";
import Third from "./Third";



export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonData: Common,
  cash: Cash,
  cashMovement: CashMovement,
  employee: Employee,
  costCenter: CostCenter,
  measureSize: MeasureSize,
  provider: Provider,
  client:Client,
  role: Role,
  listDetail: ListDetail,
  material: Material,
  unit: Unit,
  storage: Storage,
  order:Order,
  product:Product,
  purchaseOrder:PurchaseOrder,
  materialMovement:MaterialMovement,
  materialRegister:MaterialRegister,
  orderConsumption:OrderConsumption,
  productFinished:ProductFinished,
  productMovement:ProductMovement,
  productLot : ProductLot,
  guideReferral:GuideReferral,
  productSemifinished: ProductSemifinished,
  cut:Cut,
  productSemifinishedMovement:ProductSemifinishedMovement,
  productSemifinishedLot : ProductSemifinishedLot,
  purchaseOrderGeneral:PurchaseOrderGeneral,
  third:Third,
});
