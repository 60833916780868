import {
  SHOW_PRODUCTS,
  ACTIVE_PRODUCT,
  PRODUCT_STATUS_ACTIONS,
  PRODUCT_STATUS_CREATE,
  PRODUCT_LOADING,
  PRODUCT_VISIBLE,
  GET_PRODUCT,
  UPDATE_PRODUCT,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  LIST_PRODUCT
} from "../../constants/ActionTypes";

const INIT_STATE = {
  productList: [],
  productListSelect: [],
  meta: {
    totalData: 0,
    pageSize: 0,
    current: 0,
    totalPages: 0,
  },
  productObject: [],
  productStatusCreate: 0,
  productStatusActions: 0,
  loading: false,
  visible: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_PRODUCTS: {
      return {
        ...state,
        productList: action.payload
      };
    }
    case ACTIVE_PRODUCT: {
      return {
          ...state
      }
    }

    case PRODUCT_STATUS_ACTIONS: {
      return {
          ...state,
          productStatusActions:action.payload
      }
    }
    case PRODUCT_STATUS_CREATE: {
      return {
          ...state,
          productStatusCreate:action.payload
      }
    }
    case GET_PRODUCT: {
      return {
          ...state,
          productObject : action.payload
      }
  }
  case PRODUCT_LOADING: {
    return {
    ...state,
    loading: action.payload,
    }
  }
  case PRODUCT_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
  }
  case UPDATE_PRODUCT: {
    return {
        ...state
    }
  }
  case ADD_PRODUCT: {
    return {
        ...state
    }
  }
  case DELETE_PRODUCT: {
    return {
        ...state
    }
  }
  case LIST_PRODUCT: {
    return {
        ...state,
        productListSelect: action.payload,
    }
}    
   default:
      return state;
  }


};
