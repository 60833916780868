import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
  SHOW_PRODUCT_FINISHED,
  ACTIVE_PRODUCT_FINISHED,
  PRODUCT_FINISHED_STATUS_ACTIONS,
  PRODUCT_FINISHED_STATUS_CREATE,
  PRODUCT_FINISHED_LOADING,
  PRODUCT_FINISHED_VISIBLE,
  GET_PRODUCT_FINISHED,
  UPDATE_PRODUCT_FINISHED,
  DELETE_PRODUCT_FINISHED,
  ADD_PRODUCT_FINISHED
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    productFinishedList: [],
    productFinishedListSelect: [],
    meta: {
      totalData: 0,
      pageSize: 0,
      current: 0,
      totalPages: 0,
    },
    productFinishedObject: [],
   
    productFinishedStatusCreate: 0,
    productFinishedStatusActions: 0,
    loading: false,
    visible: false,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case ACTIVE_PRODUCT_FINISHED: {
        return {
            ...state
        }
      }
  
      case PRODUCT_FINISHED_STATUS_ACTIONS: {
        return {
            ...state,
            productFinishedStatusActions:action.payload
        }
      }
      case PRODUCT_FINISHED_STATUS_CREATE: {
        return {
            ...state,
            productFinishedStatusCreate:action.payload
        }
      }
      case GET_PRODUCT_FINISHED: {
        return {
            ...state,
            productFinishedObject : action.payload
        }
    }
 
    case PRODUCT_FINISHED_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
    }
    case PRODUCT_FINISHED_VISIBLE: {
        return {
        ...state,
        visible: action.payload,
        }
    }
    case UPDATE_PRODUCT_FINISHED: {
      return {
          ...state
      }
    }
    case ADD_PRODUCT_FINISHED: {
      return {
          ...state
      }
    }
    case DELETE_PRODUCT_FINISHED: {
      return {
          ...state
      }
    }
     default:
        return state;
    }
  
  
  };
  