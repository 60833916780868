import {
  ACTIVE_PRODUCT_SEMIFINISHED,
  ADD_PRODUCT_SEMIFINISHED,
  DELETE_PRODUCT_SEMIFINISHED,
  GET_PRODUCT_SEMIFINISHED,
  PRODUCT_SEMIFINISHED_STATUS_ACTIONS,
  PRODUCT_SEMIFINISHED_VISIBLE,
  SHOW_PRODUCT_SEMIFINISHED,
  UPDATE_PRODUCT_SEMIFINISHED,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  productSemifinishedList: [],
  productSemifinishedListSelect: [],
  meta: {
    totalData: 0,
    pageSize: 0,
    current: 0,
    totalPages: 0,
  },
  productSemifinishedObject: [],
  productSemifinishedStatusCreate: 0,
  productSemifinishedStatusActions: 0,
  loading: false,
  visible: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_PRODUCT_SEMIFINISHED: {
      return {
        ...state,
        productSemifinishedList: action.payload
        
      };
    }
    case PRODUCT_SEMIFINISHED_VISIBLE: {
      return {
        ...state,
        visible: action.payload,
      };
    }
    case ADD_PRODUCT_SEMIFINISHED: {
      return {
        ...state,
      };
    }
    case ACTIVE_PRODUCT_SEMIFINISHED: {
      return {
        ...state,
      };
    }
    case DELETE_PRODUCT_SEMIFINISHED: {
      return {
        ...state,
      };
    }
    case GET_PRODUCT_SEMIFINISHED: {
      return {
        ...state,
        productSemifinishedObject: action.payload,
      };
    }
    case UPDATE_PRODUCT_SEMIFINISHED: {
      return {
        ...state,
      };
    }
    case PRODUCT_SEMIFINISHED_STATUS_ACTIONS: {
      return {
        ...state,
        productSemifinishedStatusActions: action.payload,
      };
    }
    default:
      return state;
  }


};
