import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {

  let { navStyle, themeType, pathname } = useSelector(({ settings }) => settings);
  const authUser = useSelector(store => store.auth.authUser)

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (<>

    <SidebarLogo />
    <div className="gx-sidebar-content">
      <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
        { /* <UserProfile />
          <AppsNavigation />
          */
        }
      </div>
      <CustomScrollbars className="gx-layout-sider-scrollbar">
        {
          authUser ?

            authUser.idRole === 3 ?
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                mode="inline">
                <MenuItemGroup key="cash-management" className="gx-menu-group"
                  title="Control de Caja Chica">

                  <Menu.Item key="cash-management/expense-report">
                    <Link to="/cash-management/expense-report"><i className="icon icon-revenue-new" /><span>
                      Rendición de Gastos</span></Link>
                  </Menu.Item>

                </MenuItemGroup>
                <MenuItemGroup key="storage" className="gx-menu-group"
                    title="Almacén">
                    <Menu.Item key="storage/material">
                      <Link to="/storage/material"><i className="icon icon-auth-screen" /><span>
                        Insumos</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/product">
                      <Link to="/storage/product"><i className="icon icon-auth-screen" /><span>
                        Lista de Productos</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/product-finished">
                      <Link to="/storage/product-finished"><i className="icon icon-auth-screen" /><span>
                        Productos Terminados</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/cut">
                      <Link to="/storage/cut"><i className="icon icon-auth-screen" /><span>
                      Productos Semiterminados </span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/unit">
                      <Link to="/storage/unit"><i className="icon icon-auth-screen" /><span>
                        Unidades de medida</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/storage">
                      <Link to="/storage/storage"><i className="icon icon-auth-screen" /><span>
                        Gestión de Almacén</span></Link>
                    </Menu.Item>
                    <Menu.Item key="base/measure-size">
                      <Link to="/base/measure-size"><i className="icon icon-orders" /><span>
                        Gestión de Tallas</span></Link>
                    </Menu.Item>


                  </MenuItemGroup>

                  <MenuItemGroup key="order" className="gx-menu-group"
                    title="Pedidos">
                    <Menu.Item key="order/order">
                      <Link to="/order/order"><i className="icon icon-auth-screen" /><span>
                        Orden de compra</span></Link>
                    </Menu.Item>
                    <Menu.Item key="order/purchase-order">
                      <Link to="/order/purchase-order-general"><i className="icon icon-auth-screen" /><span>
                      Requerimiento de producción</span></Link>
                    </Menu.Item>
                    <Menu.Item key="order/order-consumption">
                      <Link to="/order/order-consumption"><i className="icon icon-auth-screen" /><span>
                      Requerimiento de consumo</span></Link>
                    </Menu.Item>

                  </MenuItemGroup>
              </Menu>

              : authUser.idRole === 4 ?
                <Menu
                  defaultOpenKeys={[defaultOpenKeys]}
                  selectedKeys={[selectedKeys]}
                  theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                  mode="inline">
                  <MenuItemGroup key="cash-management" className="gx-menu-group"
                    title="Control de Caja Chica">

                    <Menu.Item key="cash-management/expense-report">
                      <Link to="/cash-management/expense-report"><i className="icon icon-revenue-new" /><span>
                        Rendición de Gastos</span></Link>
                    </Menu.Item>

                  </MenuItemGroup>
                  <MenuItemGroup key="base" className="gx-menu-group"
                    title="Gestión Base">
                    <Menu.Item key="base/provider">
                      <Link to="/base/provider"><i className="icon icon-chart-radar" /><span>
                        Gestión de Proveedores</span></Link>
                    </Menu.Item>
                    <Menu.Item key="base/client">
                      <Link to="/base/client"><i className="icon icon-chart-radar" /><span>
                        Gestión de Clientes</span></Link>
                    </Menu.Item>
                    <Menu.Item key="base/third">
                      <Link to="/base/third"><i className="icon icon-chart-radar" /><span>
                        Gestión de Terceros</span></Link>
                    </Menu.Item>
                  </MenuItemGroup>
                  <MenuItemGroup key="storage" className="gx-menu-group"
                    title="Almacén">
                    <Menu.Item key="storage/material">
                      <Link to="/storage/material"><i className="icon icon-auth-screen" /><span>
                        Insumos</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/product">
                      <Link to="/storage/product"><i className="icon icon-auth-screen" /><span>
                        Lista de Productos</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/product-finished">
                      <Link to="/storage/product-finished"><i className="icon icon-auth-screen" /><span>
                        Productos Terminados</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/cut">
                      <Link to="/storage/cut"><i className="icon icon-auth-screen" /><span>
                      Productos Semiterminados </span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/unit">
                      <Link to="/storage/unit"><i className="icon icon-auth-screen" /><span>
                        Unidades de medida</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/storage">
                      <Link to="/storage/storage"><i className="icon icon-auth-screen" /><span>
                        Gestión de Almacén</span></Link>
                    </Menu.Item>
                    <Menu.Item key="base/measure-size">
                      <Link to="/base/measure-size"><i className="icon icon-orders" /><span>
                        Gestión de Tallas</span></Link>
                    </Menu.Item>


                  </MenuItemGroup>

                  <MenuItemGroup key="order" className="gx-menu-group"
                    title="Pedidos">
                    <Menu.Item key="order/order">
                      <Link to="/order/order"><i className="icon icon-auth-screen" /><span>
                        Orden de compra</span></Link>
                    </Menu.Item>
                    <Menu.Item key="order/purchase-order">
                      <Link to="/order/purchase-order-general"><i className="icon icon-auth-screen" /><span>
                      Requerimiento de producción</span></Link>
                    </Menu.Item>
                    <Menu.Item key="order/order-consumption">
                      <Link to="/order/order-consumption"><i className="icon icon-auth-screen" /><span>
                      Requerimiento de consumo</span></Link>
                    </Menu.Item>

                  </MenuItemGroup>
                </Menu>

                :
                <Menu
                  defaultOpenKeys={[defaultOpenKeys]}
                  selectedKeys={[selectedKeys]}
                  theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                  mode="inline">
                  <MenuItemGroup key="cash-management" className="gx-menu-group"
                    title="Control de Caja Chica">
                    <Menu.Item key="cash-management/cash">
                      <Link to="/cash-management/cash"><i className="icon icon-inbox" /><span>
                        Gestión de Cajas</span></Link>
                    </Menu.Item>
                    <Menu.Item key="cash-management/expense-report">
                      <Link to="/cash-management/expense-report"><i className="icon icon-revenue-new" /><span>
                        Rendición de Gastos</span></Link>
                    </Menu.Item>
                    <Menu.Item key="cash-management/report">
                      <Link to="/cash-management/report"><i className="icon icon-data-display" /><span>
                        Reportes</span></Link>
                    </Menu.Item>
                  </MenuItemGroup>
                  <MenuItemGroup key="base" className="gx-menu-group"
                    title="Gestión Base">
                    <Menu.Item key="base/employee">
                      <Link to="/base/employee"><i className="icon icon-auth-screen" /><span>
                        Gestión de Empleados</span></Link>
                    </Menu.Item>
                    <Menu.Item key="base/cost-center">
                      <Link to="/base/cost-center"><i className="icon icon-map-km-layer" /><span>
                        Centro de Costos</span></Link>
                    </Menu.Item>

                    <Menu.Item key="base/list-detail">
                      <Link to="/base/list-detail"><i className="icon icon-all-contacts" /><span>
                        Tipos de Ingreso</span></Link>
                    </Menu.Item>
                    <Menu.Item key="base/provider">
                      <Link to="/base/provider"><i className="icon icon-chart-radar" /><span>
                        Gestión de Proveedores</span></Link>
                    </Menu.Item>
                    <Menu.Item key="base/client">
                      <Link to="/base/client"><i className="icon icon-chart-radar" /><span>
                        Gestión de Clientes</span></Link>
                    </Menu.Item>
                    <Menu.Item key="base/third">
                      <Link to="/base/third"><i className="icon icon-chart-radar" /><span>
                        Gestión de Terceros</span></Link>
                    </Menu.Item>
                  </MenuItemGroup>
                  <MenuItemGroup key="storage" className="gx-menu-group"
                    title="Almacén">
                    <Menu.Item key="storage/material">
                      <Link to="/storage/material"><i className="icon icon-auth-screen" /><span>
                        Insumos</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/product">
                      <Link to="/storage/product"><i className="icon icon-auth-screen" /><span>
                        Lista de Productos</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/product-finished">
                      <Link to="/storage/product-finished"><i className="icon icon-auth-screen" /><span>
                        Productos Terminados</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/cut">
                      <Link to="/storage/cut"><i className="icon icon-auth-screen" /><span>
                      Productos Semiterminados </span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/unit">
                      <Link to="/storage/unit"><i className="icon icon-auth-screen" /><span>
                        Unidades de medida</span></Link>
                    </Menu.Item>
                    <Menu.Item key="storage/storage">
                      <Link to="/storage/storage"><i className="icon icon-auth-screen" /><span>
                        Gestión de Almacén</span></Link>
                    </Menu.Item>
                    <Menu.Item key="base/measure-size">
                      <Link to="/base/measure-size"><i className="icon icon-orders" /><span>
                        Gestión de Tallas</span></Link>
                    </Menu.Item>


                  </MenuItemGroup>

                  <MenuItemGroup key="order" className="gx-menu-group"
                    title="Pedidos">
                    <Menu.Item key="order/order">
                      <Link to="/order/order"><i className="icon icon-auth-screen" /><span>
                        Orden de compra</span></Link>
                    </Menu.Item>
                    <Menu.Item key="order/purchase-order">
                      <Link to="/order/purchase-order-general"><i className="icon icon-auth-screen" /><span>
                      Requerimiento de producción</span></Link>
                    </Menu.Item>
                    <Menu.Item key="order/order-consumption">
                      <Link to="/order/order-consumption"><i className="icon icon-auth-screen" /><span>
                      Requerimiento de consumo</span></Link>
                    </Menu.Item>

                  </MenuItemGroup>
                </Menu>
            : ''
        }

      </CustomScrollbars>
    </div>
  </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;

