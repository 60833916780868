import {
    SHOW_ORDERS,
    ACTIVE_ORDER,
    ORDER_STATUS_ACTIONS,
    ORDER_STATUS_CREATE,
    ORDER_LOADING,
    ORDER_VISIBLE,
    GET_ORDER,
    UPDATE_ORDER,
    ADD_ORDER,
    DELETE_ORDER,
    LIST_ORDER
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    orderList: [],
    orderListSelect: [],
    meta: {
      totalData: 0,
      pageSize: 0,
      current: 0,
      totalPages: 0,
    },
    orderObject: [],
   
    orderStatusCreate: 0,
    orderStatusActions: 0,
    loading: false,
    visible: false,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case SHOW_ORDERS: {
        return {
          ...state,
          orderList: action.payload
        };
      }
      case LIST_ORDER: {
        return {
            ...state,
            orderListSelect:action.payload
        }
      }
      case ACTIVE_ORDER: {
        return {
            ...state
        }
      }
  
      case ORDER_STATUS_ACTIONS: {
        return {
            ...state,
            orderStatusActions:action.payload
        }
      }
      case ORDER_STATUS_CREATE: {
        return {
            ...state,
            orderStatusCreate:action.payload
        }
      }
      case GET_ORDER: {
        return {
            ...state,
            orderObject : action.payload
        }
    }
 
    case ORDER_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
    }
    case ORDER_VISIBLE: {
        return {
        ...state,
        visible: action.payload,
        }
    }
    case UPDATE_ORDER: {
      return {
          ...state
      }
    }
    case ADD_ORDER: {
      return {
          ...state
      }
    }
    case DELETE_ORDER: {
      return {
          ...state
      }
    }
     default:
        return state;
    }
  
  
  };
  