import {
    LIST_EMPLOYEES,
    SHOW_EMPLOYEES,
    EMPLOYEE_STATUS_ACTIONS,
    EMPLOYEE_STATUS_CREATE,
    EMPLOYEE_LOADING,
    EMPLOYEE_VISIBLE,
    ADD_EMPLOYEE,
    GET_EMPLOYEE,
    DELETE_EMPLOYEE,
    ACTIVE_EMPLOYEE,
    UPDATE_EMPLOYEE,
    UPDATE_ROLE,
    EMPLOYEE_UPDATE_PASSWORD,
} from "../../constants/ActionTypes";

const INIT_STATE = {
    employeeList: [],
    employeeListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    employeeObject: [],
    employeeStatusCreate: 0,
    employeeStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case LIST_EMPLOYEES: {
            return {
            ...state,
            employeeListSelect: action.payload,
            }
        }

        case SHOW_EMPLOYEES: {
            return {
                ...state,
                employeeList: action.payload
            }
        }

        case EMPLOYEE_STATUS_ACTIONS: {
            return {
                ...state,
                employeeStatusActions:action.payload
            }
        }
        
        case EMPLOYEE_STATUS_CREATE: {
            return {
                ...state,
                employeeStatusCreate:action.payload
            }
        }

        case EMPLOYEE_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case EMPLOYEE_VISIBLE: {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_EMPLOYEE: {
            return {
                ...state
            }
        }
      
        case GET_EMPLOYEE: {
            return {
                ...state,
                employeeObject : action.payload
            }
        }


        case DELETE_EMPLOYEE: {
            return {
                ...state
            }
        }

        case ACTIVE_EMPLOYEE: {
            return {
                ...state
            }
        }

        case UPDATE_EMPLOYEE: {
            return {
                ...state
            }
        }

        case UPDATE_ROLE: {
            return {
                ...state
            }
        }
        case EMPLOYEE_UPDATE_PASSWORD: {
            return {
                ...state
            }
        }
      default:
        return state;
    }
  }
  