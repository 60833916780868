import {
    SHOW_CASHES,
    CASH_LOADING,
    CASH_VISIBLE,
    CASH_STATUS_CREATE,
    CASH_STATUS_ACTIONS,
    ADD_CASH,
    LIST_CASHES,
    CASH_GETINFO,
    DELETE_CASH,
    ACTIVE_CASH,
    UPDATE_CASH,
    GET_CASH,
    OPEN_CASH,
    CLOSE_CASH,
    LIST_OPENING

} from "../../constants/ActionTypes";

const INIT_STATE = {
    cashList: [],
    cashListSelect:[],
    openingListSelect:[],
    cashInfo:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    cashObject: [],
    cashStatusCreate: 0,
    cashStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_CASHES: {
            return {
                ...state,
                cashList: action.payload
            }
        }

        case CASH_STATUS_ACTIONS: {
            return {
                ...state,
                cashStatusActions:action.payload
            }
        }
        case CASH_STATUS_CREATE: {
            return {
                ...state,
                cashStatusCreate:action.payload
            }
        }

        case CASH_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case CASH_VISIBLE: {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_CASH: {
            return {
                ...state
            }
        }

      
        case LIST_CASHES: {
            return {
                ...state,
                cashListSelect: action.payload,
            }
        }

        case LIST_OPENING: {
            return {
                ...state,
                openingListSelect: action.payload,
            }
        }

        case CASH_GETINFO: {
            return {
            ...state,
            cashInfo: action.payload,
            }
        }

        case DELETE_CASH: {
            return {
                ...state
            }
        }

        case ACTIVE_CASH: {
            return {
                ...state
            }
        }

        case UPDATE_CASH: {
            return {
                ...state
            }
        }

        case GET_CASH: {
            return {
                ...state,
                cashObject : action.payload
            }
        }

        case OPEN_CASH: {
            return {
                ...state
            }
        }

        case CLOSE_CASH: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  