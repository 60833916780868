import {
    SHOW_PURCHASEORDERS,
    ACTIVE_PURCHASEORDER,
    PURCHASEORDER_STATUS_ACTIONS,
    PURCHASEORDER_STATUS_CREATE,
    PURCHASEORDER_LOADING,
    PURCHASEORDER_VISIBLE,
    GET_PURCHASEORDER,
    UPDATE_PURCHASEORDER,
    ADD_PURCHASEORDER,
    DELETE_PURCHASEORDER
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    purchaseOrderList: [],
    purchaseOrderListSelect: [],
    meta: {
      totalData: 0,
      pageSize: 0,
      current: 0,
      totalPages: 0,
    },
    purchaseOrderObject: [],
   
    purchaseOrderStatusCreate: 0,
    purchaseOrderStatusActions: 0,
    loading: false,
    visible: false,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case SHOW_PURCHASEORDERS: {
        return {
          ...state,
          purchaseOrderList: action.payload
        };
      }
      case ACTIVE_PURCHASEORDER: {
        return {
            ...state
        }
      }
  
      case PURCHASEORDER_STATUS_ACTIONS: {
        return {
            ...state,
            purchaseOrderStatusActions:action.payload
        }
      }
      case PURCHASEORDER_STATUS_CREATE: {
        return {
            ...state,
            purchaseOrderStatusCreate:action.payload
        }
      }
      case GET_PURCHASEORDER: {
        return {
            ...state,
            purchaseOrderObject : action.payload
        }
    }
 
    case PURCHASEORDER_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
    }
    case PURCHASEORDER_VISIBLE: {
        return {
        ...state,
        visible: action.payload,
        }
    }
    case UPDATE_PURCHASEORDER: {
      return {
          ...state
      }
    }
    case ADD_PURCHASEORDER: {
      return {
          ...state
      }
    }
    case DELETE_PURCHASEORDER: {
      return {
          ...state
      }
    }
     default:
        return state;
    }
  
  
  };
  