import {
    SHOW_PURCHASE_ORDER_GENERAL,
    ACTIVE_PURCHASE_ORDER_GENERAL,
    PURCHASE_ORDER_GENERAL_STATUS_ACTIONS,
    PURCHASE_ORDER_GENERAL_STATUS_CREATE,
    PURCHASE_ORDER_GENERAL_LOADING,
    PURCHASE_ORDER_GENERAL_VISIBLE,
    GET_PURCHASE_ORDER_GENERAL,
    UPDATE_PURCHASE_ORDER_GENERAL,
    ADD_PURCHASE_ORDER_GENERAL,
    DELETE_PURCHASE_ORDER_GENERAL,
    LIST_PURCHASE_ORDER_GENERAL
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    purchaseOrderGeneralList: [],
    purchaseOrderGeneralListSelect: [],
    meta: {
      totalData: 0,
      pageSize: 0,
      current: 0,
      totalPages: 0,
    },
    purchaseOrderGeneralObject: [],
   
    purchaseOrderGeneralStatusCreate: 0,
    purchaseOrderGeneralStatusActions: 0,
    loading: false,
    visible: false,
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case SHOW_PURCHASE_ORDER_GENERAL: {
        return {
          ...state,
          purchaseOrderGeneralList: action.payload,
          
        };
      }
      case ACTIVE_PURCHASE_ORDER_GENERAL: {
        return {
            ...state
        }
      }
      case LIST_PURCHASE_ORDER_GENERAL: {
        return {
            ...state,
            purchaseOrderGeneralListSelect:action.payload
        }
      }
      case PURCHASE_ORDER_GENERAL_STATUS_ACTIONS: {
        return {
            ...state,
            purchaseOrderGeneralStatusActions:action.payload
        }
      }
      case PURCHASE_ORDER_GENERAL_STATUS_CREATE: {
        return {
            ...state,
            purchaseOrderGeneralStatusCreate:action.payload
        }
      }
      case GET_PURCHASE_ORDER_GENERAL: {
        return {
            ...state,
            purchaseOrderGeneralObject : action.payload
        }
    }
 
    case PURCHASE_ORDER_GENERAL_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
    }
    case PURCHASE_ORDER_GENERAL_VISIBLE: {
        return {
        ...state,
        visible: action.payload,
        }
    }
    case UPDATE_PURCHASE_ORDER_GENERAL: {
      return {
          ...state
      }
    }
    case ADD_PURCHASE_ORDER_GENERAL: {
      return {
          ...state
      }
    }
    case DELETE_PURCHASE_ORDER_GENERAL: {
      return {
          ...state
      }
    }
     default:
        return state;
    }
  
  
  };
  