import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_PRODUCT_SEMIFINISHED_LOT,
  SHOW_PRODUCT_SEMIFINISHED_LOT
} from "../../constants/ActionTypes";

const INIT_STATE = {
  productSemifinishedLotList: [],
  meta: {
    totalData: 0,
    pageSize: 0,
    current: 0,
    totalPages: 0,
  },
  productSemifinishedLotObject: []

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_PRODUCT_SEMIFINISHED_LOT: {
      return {
        ...state,
        productSemifinishedLotObject: action.payload
      }
    }

    case SHOW_PRODUCT_SEMIFINISHED_LOT: {
      return {
        ...state,
        productSemifinishedLotList: action.payload.data,
        meta: {
          totalData: action.payload.meta.total,
          pageSize: action.payload.meta.per_page,
          current: action.payload.meta.current_page,
          totalPages: action.payload.meta.last_page,
        },
      };
    }

    default:
      return state;
  }


};
