import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import { Link } from "react-router-dom";

import UserPNG from "../../assets/images/user.png";
const UserInfo = () => {

  const dispatch = useDispatch();
  const current_user = useSelector(store => store.auth.authUser);

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><Link to="/user">Nueva contraseña</Link></li>
      <li onClick={() => dispatch(userSignOut())}>Cerrar Sesión
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <Avatar src={UserPNG}
              className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  )

}

export default UserInfo;
