import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";


const Base = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/cost-center`}/>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/measure-size`}/>
    <Route path={`${match.url}/employee`} component={asyncComponent(() => import('./Employee'))}/>
    <Route path={`${match.url}/cost-center`} component={asyncComponent(() => import('./CostCenter'))}/>
    <Route path={`${match.url}/measure-size`} component={asyncComponent(() => import('./MeasureSize'))}/>
    <Route path={`${match.url}/provider`} component={asyncComponent(() => import('./Provider'))}/>
    <Route path={`${match.url}/client`} component={asyncComponent(() => import('./Client'))}/>
    <Route path={`${match.url}/list-detail`} component={asyncComponent(() => import('./ListDetail'))}/>
    <Route path={`${match.url}/third`} component={asyncComponent(() => import('./Third'))}/>

  </Switch>
);

export default Base;
