import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_PRODUCT_LOT,
  SHOW_PRODUCT_LOT
} from "../../constants/ActionTypes";

const INIT_STATE = {
  productLotList: [],
  meta: {
    totalData: 0,
    pageSize: 0,
    current: 0,
    totalPages: 0,
  },
  productLotObject: []

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_PRODUCT_LOT: {
      return {
        ...state,
        productLotObject: action.payload
      }
    }

    case SHOW_PRODUCT_LOT: {
      return {
        ...state,
        productLotList: action.payload
      };
    }

    default:
      return state;
  }


};
