import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";


const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    console.log("finish", values)
    dispatch(userSignIn(values));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/');
    }
  }, [token, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">

            
            <div className="gx-app-logo" style={{textAlign:'center'}}>
              <img alt="example" src={require("assets/images/logo_pyr.png")} style={{width:'60px',textAlign:'center'}}/>
            </div>
            <div className="">
              <h1 style={{ color: 'black' }}>Iniciar Sesión</h1>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <Form.Item
                rules={[{ required: true, message: 'Usuario requerido' }]} name="email">
                <Input placeholder="Usuario" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Contraseña requerida' }]} name="password">
                <Input type="password" placeholder="Contraseña" />
              </Form.Item>

              <Form.Item>
                <Button type="danger" className="gx-mb-0" htmlType="submit">
                  Iniciar Sesión
                </Button>

              </Form.Item>

            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
