import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
  ADD_MATERIAL_REGISTER,
  GET_MATERIAL_REGISTER,
  MATERIAL_REGISTER_VISIBLE
} from "../../constants/ActionTypes";

const INIT_STATE = {
  materialRegisterList: [],
  materialRegisterListSelect: [],
  meta: {
    totalData: 0,
    pageSize: 0,
    current: 0,
    totalPages: 0,
  },
  materialRegisterObject: [],
  materialRegisterStatusCreate: 0,
  materialRegisterStatusActions: 0,
  loading: false,
  visible: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_MATERIAL_REGISTER: {
      return {
        ...state,
      };
    }

    case GET_MATERIAL_REGISTER: {
      return {
        ...state,
        materialRegisterObject: action.payload,
      };
    }

    case MATERIAL_REGISTER_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
  }



    default:
      return state;
  }
};
