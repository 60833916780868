import {
    SHOW_THIRD,
    THIRD_LOADING,
    THIRD_VISIBLE,
    THIRD_STATUS_CREATE,
    THIRD_STATUS_ACTIONS,
    ADD_THIRD,
    LIST_THIRD,
    DELETE_THIRD,
    GET_THIRD,
    UPDATE_THIRD,

} from "../../constants/ActionTypes";

const INIT_STATE = {
    thirdList: [],
    thirdListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    thirdObject: [],
    thirdStatusCreate: 0,
    thirdStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_THIRD: {
            return {
                ...state,
                thirdList: action.payload
            }
        }

        case THIRD_STATUS_ACTIONS: {
            return {
                ...state,
                thirdStatusActions:action.payload
            }
        }
        
        case THIRD_STATUS_CREATE: {
            return {
                ...state,
                thirdStatusCreate:action.payload
            }
        }

        case THIRD_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case THIRD_VISIBLE: {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_THIRD: {
            return {
                ...state
            }
        }
      
        case GET_THIRD: {
            return {
                ...state,
                thirdObject : action.payload
            }
        }

        case LIST_THIRD: {
            return {
                ...state,
                thirdListSelect: action.payload,
            }
        }      

        case DELETE_THIRD: {
            return {
                ...state
            }
        }


        case UPDATE_THIRD: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  