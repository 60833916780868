import {
    SHOW_COST_CENTERS,
    COST_CENTER_LOADING,
    COST_CENTER_VISIBLE,
    COST_CENTER_STATUS_CREATE,
    COST_CENTER_STATUS_ACTIONS,
    ADD_COST_CENTER,
    LIST_COST_CENTERS,
    ACTIVE_COST_CENTER,
    DELETE_COST_CENTER,
    GET_COST_CENTER,
    UPDATE_COST_CENTER,

} from "../../constants/ActionTypes";

const INIT_STATE = {
    costCenterList: [],
    costCenterListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    costCenterObject: [],
    costCenterStatusCreate: 0,
    costCenterStatusActions: 0,
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_COST_CENTERS: {
            return {
                ...state,
                costCenterList: action.payload
            }
        }

        case COST_CENTER_STATUS_ACTIONS: {
            return {
                ...state,
                costCenterStatusActions:action.payload
            }
        }
        
        case COST_CENTER_STATUS_CREATE: {
            return {
                ...state,
                costCenterStatusCreate:action.payload
            }
        }

        case COST_CENTER_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case COST_CENTER_VISIBLE: {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_COST_CENTER: {
            return {
                ...state
            }
        }
      
        case GET_COST_CENTER: {
            return {
                ...state,
                costCenterObject : action.payload
            }
        }

        case LIST_COST_CENTERS: {
            return {
                ...state,
                costCenterListSelect: action.payload,
            }
        }      

        case DELETE_COST_CENTER: {
            return {
                ...state
            }
        }

        case ACTIVE_COST_CENTER: {
            return {
                ...state
            }
        }

        case UPDATE_COST_CENTER: {
            return {
                ...state
            }
        }

      default:
        return state;
    }
  }
  