import {
    LIST_ROLES,

} from "../../constants/ActionTypes";

const INIT_STATE = {
    roleListSelect:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {       

        case LIST_ROLES: {
            return {
                ...state,
                roleListSelect: action.payload,
            }
        }      

      default:
        return state;
    }
  }
  